import { Spin, Result, Card, Button } from 'antd';
import useQueryParamsOnload from 'hooks/useQueryParamsOnload';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Post } from 'routes/AgencyCostManagement/request';

export default function StripeAuthCallback() {
  const routerParams = useParams();
  const queryParams = useQueryParamsOnload();
  const [status, setStatus] = useState(routerParams.result === 'success' ? 'success' : 'error');
  const [loading, setLoading] = useState(true);

  const stripeAccountId = queryParams.stripe_account_id;

  useEffect(() => {
    (async () => {
      if(routerParams.result === 'failed' || !stripeAccountId) {
        setLoading(false);
        return;
      }
      try {
        const callbackRes = await Post('/agency/client/stripe/status', {
          data: { stripe_account_id: stripeAccountId },
          cancelMessage: true
        });
        console.log(callbackRes);
        if ([2000, 2010].includes(callbackRes.code)) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  const onReturnToDSers = () => {
    window.location.href = `${window.location.origin}/app/agency-cost-management?key=${queryParams.key}`;
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ff8f00'
      }}
    >
      <Card
        style={{
          width: 473
        }}
      >
        {!loading ? (
          <Result
            status={status}
            title={status === 'success' ? 'Stripe授权成功' : 'Stripe授权失败'}
            extra={
              queryParams.key
                ? [
                    <Button type="primary" onClick={onReturnToDSers}>
                      返回DSers
                    </Button>
                  ]
                : undefined
            }
          />
        ) : (
          <Spin spinning={loading}>
            <div style={{ height: 312 }}></div>
          </Spin>
        )}
      </Card>
    </div>
  );
}
